import React, {useContext, Fragment} from 'react';
import { Trans } from "@lingui/macro";
import { Sections } from 'components/Sections/Sections';
import { Columns } from 'components/Columns/Columns';
import ThemeContext from 'context/ThemeContext';
import styles from './Personvern.module.scss';

const Personvern: React.FC = () => {
  const { dark } = useContext(ThemeContext);

  return (
    <div className={`${dark ? styles.dark : styles.light} ${styles.container}`}>
      <Sections className={styles.contact} id="personvern" type="intro" bigText={<Trans>Personvern</Trans>} smallText={<Fragment><Trans>Vårt formål med bruk av cookies er å gi innhold og annonser et personlig preg, samt å kunne analysere trafikken vår.</Trans></Fragment>} />
      <div className={styles.personvern}>
        <Columns>
          <div>
            <h2><Trans>Hva er en cookie?</Trans></h2>
            <p><Trans>En cookie (også kalt informasjonskapsel) er en liten tekst-fil som lagres på din PC etter du har besøkt vårt nettsted. Denne lagres slik at vi kan holde orden på hva som skjer når du bruker nettstedet og for vi skal kunne gjenkjenne din PC. Den gir oss også en statistikk som kan bidra til at vi kan gjøre nettstedet bedre for våre brukere. En cookie-fil inneholder kun tekst.</Trans></p>
            <p><Trans>Med mindre du har innstilt nettleseren din på å avvise cookies, vil disse opprettes automatisk. Om du vil tillate lagring av cookies bestemmer du selv. Vær bare oppmerksom på at ved å avvise cookie kan det påvirke funksjonaliteten på nettstedet.</Trans></p>
          </div>
        </Columns>
        <Columns>
          <div>
            <h2><Trans>Hvilke cookies bruker vi?</Trans></h2>
            <p><Trans>Goodbyte setter ingen cookies som kan knyttes til deg som person, men dersom andre personer bruker din datamaskin vil de kunne få opp innhold som er basert på dine preferanser.</Trans></p>
            <p><Trans>Vårt formål med bruk av cookies er å gi innhold og annonser et personlig preg, samt å kunne analysere trafikken vår. Informasjonskapslene som vi bruker kan ikke på noen måte spre skadelige programmer eller skade din datamaskin.</Trans></p>
            <p><Trans><strong>Ved å benytte vårt nettsted goodbyte.no, samtykker du i at det plasseres cookies på din datamaskin</strong>.</Trans></p>
            <p><Trans>Her er oversikten over hvilke cookies som benyttes på goodbyte.no:</Trans></p>
            <Trans>
              <ul>
                <li>_ga - Google Analytics: Analyseformål.</li>
                <li>_gid - Google Analytics: Analyseformål</li>
                <li>_hjid - Hotjar: Analyseformål</li>
                <li>_hjptid - Hotjar: Analyseformål</li>
              </ul>
            </Trans>
          </div>
        </Columns>
        <Columns>
          <div>
            <h2><Trans>Sporing &amp; monitorering</Trans></h2>
            <p><Trans>Goodbyte benytter en tjeneste for sporing og monitorering av nettsidens brukere. Sporingsverktøyet bruker cookies for å samle inn data om brukernes adferdsmønster og teknologi:</Trans></p>
            <Trans>
              <ul>
                <li>IP-adresse som blir lagret i anonymisert form</li>
                <li>Skjermstørrelse</li>
                <li>Unike enhetsidentifikatorer</li>
                <li>Nettleserinformasjon (+ foretrukket språk)</li>
                <li>Geografisk plassering (Begrenset til land)</li>
              </ul>
            </Trans>
            <p><Trans>Informasjonen blir lagret i en pseudonymisert brukerprofil. Informasjon som samles inn på goodbyte.no vil aldri, under noen omstendighet, bli delt eller solgt. Den kan heller ikke identifisere deg som person, men vil kun bli brukt til å analysere brukeratferd for å forbedre nettstedet vårt.</Trans></p>
            <p><Trans>Du kan <a href="https://www.hotjar.com/legal/compliance/opt-out">skru av opprettingen av en profil</a> fra vår tjenesteleverandør.</Trans></p>
          </div>
        </Columns>
        <Columns><div><h2><Trans>Rettigheter</Trans></h2>
          <p><Trans>Alle som spør har rett på grunnleggende informasjon om behandlinger av personopplysninger i en virksomhet etter personopplysningslovens § 18, 1. ledd. Goodbyte har gitt denne informasjonen i denne erklæringen, og vil henvise til den ved eventuelle forespørsler. De som er registrert i en av Goodbytes systemer har rett på innsyn i egne opplysninger. Vedkommende har også rett til å be om at uriktige, ufullstendige eller opplysninger Goodbyte ikke har adgang til å behandle blir rettet, slettet eller supplert. Krav fra den registrerte skal besvares kostnadsfritt og senest innen 30 dager.</Trans></p>
          <p><Trans>Henvendelser om personopplysninger i Goodbyte kan rettes til <a href="mailto:personvern@goodbyte.no">personvern@goodbyte.no</a>.</Trans></p></div></Columns>
      </div>
    </div>
  );
}

export default Personvern;
