import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import { I18nProvider } from '@lingui/react'
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from './context/ThemeContext';
// import Logo from '../public/img/logo.svg';
import { ReactComponent as Logo } from "./logo-white.svg";
// import { i18n, defaultLocale } from "./i18n";
// i18n.activate(defaultLocale);

async function loadMessages(language: any) {
  return await import(`@lingui/loader!./locales/${language}/messages.po`);
}
function LocalizedApp() {
  const [catalogs, setCatalogs] = useState({});
  const [language, setLanguage] = useState('no');
  async function handleLanguageChange(language:string) {
    const newCatalog = await loadMessages(language);
    const newCatalogs = { ...catalogs, [language]: newCatalog };
    setCatalogs(newCatalogs);
    setLanguage(language);
  }
  return (
    <ThemeProvider>
      <div id="loader-container">
        <Logo className="logo" />
      </div>
      <I18nProvider language={language} catalogs={catalogs}>
        <App
          language={language}
          onLanguageChange={handleLanguageChange}
        />
      </I18nProvider>
    </ThemeProvider>
  );
}

ReactDOM.render(
  <LocalizedApp />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
