import React, { useContext, FunctionComponent } from 'react';

import ThemeContext from '../../context/ThemeContext';
import styles from './ThemeSwitch.module.scss';

export const ThemeSwitch: FunctionComponent = () => {
  const { dark, toggle } = useContext(ThemeContext);
  
  return (
    <div className={styles['theme-switcher']}>
    <label id={styles.switch} className={styles.switch}>
      <input type="checkbox" onChange={() => {
        toggle()
      }} checked={!dark} id={styles.slider} />
      <span className={`${styles.slider} ${styles.round}`}></span>
    </label>
    </div>
  );
}