import React, {useContext, Fragment} from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Trans } from "@lingui/macro";
import { Sections } from 'components/Sections/Sections';
import ThemeContext from 'context/ThemeContext';
import styles from './About.module.scss';
// import { Person } from '../../components/Person/Person';
// import { Loading } from '../../components/Loading/Loading';
import { Columns } from '../../components/Columns/Columns';
import { Animate } from '../../components/Animate/Animate';

// import technip from './technipfmc.svg';
import { ReactComponent as TechnipFMC } from "./clients/technipfmc.svg";
import { ReactComponent as CanalDigital } from "./clients/canaldigital.svg";
import { ReactComponent as GodtLevert } from "./clients/godtlevert.svg";
import { ReactComponent as RiksTV } from "./clients/rikstv.svg";
import { ReactComponent as Gjensidige } from "./clients/gjensidige.svg";
// import { ReactComponent as Nortura } from "./clients/nortura-color.svg"

const About: React.FC = () => {
  const { dark } = useContext(ThemeContext);

  return (
    <div className={`${dark ? styles.dark : styles.light}`}>
      <Sections className={styles.contact} id="about" type="intro" bigText={<Trans>Om oss</Trans>} smallText={<Fragment><Trans>Vårt oppdrag er å løse våre kunders mest utfordrende og komplekse problemer, noe som gjør deres mål til vårt mål.</Trans></Fragment>} />
      <Sections className={styles.content}>
        <div className={styles['about-intro']}>
          <ScrollAnimation animateIn={styles.animated} className={styles.line} animateOnce delay={500} offset={-100} />
          <Columns noPadding>
            <Animate type="slide-lr" className={styles.text} animateOnce offset={200}>
              <p><Trans>Den 02.02.2020 ble Goodbyte til. Vi er en gruppe frilansere som jobber sammen for å utvikle fantastiske merkevarer, lage livslange forbindelser og bygge en haug med morsomme ting.</Trans></p>
            </Animate>
            <Animate type="slide-rl" className={styles.img} animateOnce offset={100}>
              <picture>
                <source srcSet="/img/about-lying.webp" type="image/webp" />
                <source srcSet="/img/about-lying.jpg" type="image/jpeg" /> 
                <img src="/img/about-lying-placeholder.png" alt="Office" />
              </picture>
            </Animate>
          </Columns>
          <Columns noOverflowHidden>
            <Animate type="slide-lr" className={styles.img2} animateOnce>
              <picture>
                <source srcSet="/img/about-standing.webp" type="image/webp" />
                <source srcSet="/img/about-standing.jpg" type="image/jpeg" /> 
                <img src="/img/about-standing-placeholder.png" alt="Office2" />
              </picture>
            </Animate>
            <Animate type="slide-rl" className={styles.text2} animateOnce>
              <h2><Trans>Hvordan Goodbyte-teamet jobber</Trans></h2>
              <p><Trans>Noen ganger jobber vi sammen, andre ganger jobber vi alene, andre ganger i forskjellige tidssoner. Vi holder magien i live med god kommunikasjon.</Trans></p>
            </Animate>
          </Columns>
        </div>
        <Columns>
          <h1><Trans>Verdiene våre</Trans></h1>
        </Columns>
        <Columns type="halve"><h2 className={styles.introheadline}><Trans>Vær...</Trans></h2></Columns>
        <Columns className={styles.values} type="halve">
          <Animate type="intro-element" animateOnce>
            <h2><Trans>lidenskapelig</Trans></h2>
            <p><Trans>Vi er en lidenskapelig gruppe individer som kommer sammen om ett kollektivt mål - bygge det beste produktet mulig innenfor gitte rammer.</Trans></p>
          </Animate>
          <Animate type="intro-element-reverse" animateOnce>
            <h2><Trans>bemerkelsesverdig</Trans></h2>
            <p><Trans>Vi er en variert gruppe talentfulle mennesker som elsker å utforske talentene sine på og utenfor kontoret.</Trans></p>
          </Animate>
          <Animate type="intro-element" animateOnce>
            <h2><Trans>fryktløs</Trans></h2>
            <p><Trans>Vi frykter ikke fiasko. Faktisk omfavner vi det. Vi tror at våre feil fører til fremgang.</Trans></p>
          </Animate>
          <Animate type="intro-element-reverse" animateOnce>
            <h2><Trans>gjennomsiktig</Trans></h2>
            <p><Trans>Vi er ærlige med hverandre. Vi er ikke redde for å være ærlige og direkte fordi vi vet at vi prøver å forbedre hverandre.</Trans></p>
          </Animate>
        </Columns>
        {/* <Columns>
          <div>
            <h2>Hvordan Goodbyte-teamet jobber</h2>
            <p>Noen ganger jobber vi sammen, andre ganger jobber vi alene, andre ganger i forskjellige tidssoner. Vi holder magien i live med god kommunikasjon.</p>
          </div>
        </Columns> */}
        <div className={styles.clients}>
          <p className={styles.intro}>
            {/* Her er noen av de heldige, som vi har vært heldige å få tilbringe tid sammen med: */}
            <Trans>Her er noen av dem vi har vært heldige å få tilbringe tid sammen med:</Trans>
          </p>
          <TechnipFMC />
          <CanalDigital />
          <GodtLevert />
          <RiksTV />
          {/* <Nortura /> */}
          <Gjensidige />
        </div>
      </Sections>
      {/* <div style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap', position: 'relative'}}>
        <div style={{position: 'absolute', backgroundColor: '#f9f9f9', width: '80%', height: 'calc(100% - 300px)', left: '0', top: '300px'}} />
        <Person name="Jonas Hasle" title="Seniorkonsulent" keywords="gamer / lesehest / treningsnarkoman" img="/img/capture.png" />
        <Person name="Jonas Hasle" title="Seniorkonsulent" keywords="mtg-entusiast / geek / prosjektstarter" img="/img/capture.png" alignRight />
      </div> */}

    </div>
  );
}

export default About;
