import React, { FunctionComponent } from 'react';
import { Link } from "react-scroll";
import './tagline.scss';

type TaglineProps = {
  width?: string;
  dark?: boolean;
  position?: string;
}

export const Tagline: FunctionComponent<TaglineProps> = ({ dark = false, children }) => <div className={`tagline ${dark ? 'dark' : 'light'}`}><div className="left"></div>{children}<div className="right"><Link to="about" smooth={true} offset={34} className="next" /></div></div>
