import React, { FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';
import styles from './Button.module.scss';

type ButtonProps = {
  href?: string;
  className?: string;
  onClick?: any;
  button?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = ({ href = '#', className = '', onClick = undefined, button = false, children }) => { 
  const dark = useContext(ThemeContext).dark;

  return (
    !button ? 
      <Link to={href} className={`${styles.link} ${className !== '' ? styles[className] : ''} ${dark ? styles.dark : styles.light}`} onClick={onClick}>{children}</Link>
    :
      <button type="submit" className={`${styles.link} ${className !== '' ? styles[className] : ''} ${dark ? styles.dark : styles.light}`}>{children}</button>
  );
};