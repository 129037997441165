import React, {useContext, useState} from 'react';
import { Trans } from "@lingui/macro";
import { Sections } from 'components/Sections/Sections';
import { Logo } from 'components/Logo/Logo';
import { Tagline } from 'components/Tagline/Tagline';
import { Button } from 'components/Button/Button';
import { Columns } from 'components/Columns/Columns';
import { Animate } from 'components/Animate/Animate';
import ThemeContext from 'context/ThemeContext';
import styles from './Home.module.scss';

import ScrollAnimation from 'react-animate-on-scroll';

const Home: React.FC = () => {
  const { dark } = useContext(ThemeContext);

  const [showKeywords, setShowKeywords] = useState(false);

  // const keywords = () => {
    
  // }

  return (
    <div className={`${dark ? styles.dark + ' dark' : styles.light}`}>
      <Sections type="splash">
        <Logo dark={!dark} position="center" />
        <Tagline dark={dark}><h2><Trans>Trenger du dyktige IT-konsulenter?</Trans></h2></Tagline>
        {/* <p>Goodbyte integrer strategi, design og teknologi for å bygge det <i>neste</i>.</p> */}
      </Sections>
      <Sections className={styles.about} id="about">
        <ScrollAnimation offset={5} animateIn={styles.animate} animateOnce><div className={`${styles['about-line']}`} /></ScrollAnimation>
        <Animate type="intro-element" animateOnce>
          <h1><Trans>Vi i <strong>Goodbyte</strong> integrer strategi, design og teknologi for å bygge det <i> neste</i>.</Trans></h1>
        </Animate>
        <div className={styles['two-box']}>
          <Animate type="slide-lr" animateOnce>
            {/* <img src="/img/about-home.jpg" alt="" /> */}
            <picture>
              <source srcSet="/img/about-home.webp" type="image/webp" />
              <source srcSet="/img/about-home.jpg" type="image/jpeg" /> 
              <img src="/img/about-home.jpg" alt="Alt Text!" />
            </picture>
          </Animate>
          <Animate type="slide-rl" animateOnce>
            <p><Trans>Goodbyte er basert i Oslo og Viken, hvor vi gjennomfører prosjekter for både store og små kunder. Onsite eller remote.</Trans></p>
            <Button href="/om-oss"><Trans>Les mer</Trans></Button>
          </Animate>
        </div>
      </Sections>
      <Sections className={styles.competency}>
        <Animate type="slide-lr-short" animateOnce>
          <Columns>
            <h1><Trans>Vår kompetanse</Trans></h1>
          </Columns>
        </Animate>
          <Columns className={!showKeywords ? styles.info : styles.buzzwords}>
            <Animate type="slide-lr-short" delay={100} animateOnce>
              <i className={`${styles.fab} ${styles['fa-react']}`}></i>
              <h2><Trans>Frontend</Trans></h2>
              <div className={styles.box}>

              <div className={styles.info}>
                {/* <p>Vi skriver koden som er ansvarlig for det du kan se og interagere med når du åpner en nettside, et program eller en app.</p> */}
                {/* <p>En utfordring med å utvikle for web i dag, er at det må fungere på tvers av ulike nettlesere og operativsystemer, og ikke minst – både på små og store skjermer.</p> */}
                <p><Trans>Det å lage noe som både funge­­&shy;rer, som er pent, og som gir en god bruker­­&shy;opplevel­­&shy;se uan­­&shy;sett en­­&shy;het, skjerm­­&shy;størrelse, nett­­&shy;leser og operativ­­&shy;system – det er en kunst.</Trans></p>
              </div>
              <div className={styles.buzzwords}>
                <p><Trans>HTML og CSS. Java­­&shy;Script og biblio­­&shy;teker, herav blant annet: React, Vue &amp; Angular. Type­­&shy;Script &amp; test-rammeverk som Jest. Gulp, Grunt &amp; Webpack.</Trans></p>
              </div>
              </div>
            </Animate>
            <Animate type="slide-lr-short" delay={200} animateOnce>
              <i className={`${styles.fas} ${styles['fa-laptop']}`}></i>
              <h2><Trans>Backend</Trans></h2>
              <div className={styles.box}>

              <div className={styles.info}>
                <p><Trans>Vi jobber også med det som ikke er syn­­&shy;lig for slutt­­&shy;bruker­­&shy;en, og er på server­­&shy;siden, som gjør at alt funge­­&shy;rer. Det kan for eksem­­&shy;pel være admini­­&shy;stre­­&shy;ring av data­­&shy;baser, app­­&shy;likasjons­­&shy;koding og hånd­­&shy;te­­&shy;ring av serve­­&shy;re. Selve kjernen i app­­&shy;likasjo­­&shy;nen.</Trans></p>
              </div>
              <div className={styles.buzzwords}><p><Trans>Node, Python, PHP, Java, .NET, MongoDB, PostgresQL, MySQL</Trans></p></div>
              </div>
            </Animate>
            <Animate type="slide-lr-short" delay={300} animateOnce>
              <i className={`${styles.fab} ${styles['fa-docker']}`}></i>
              <h2><Trans>DevOps</Trans></h2>
              <div className={styles.box}>
              <div className={styles.info}>
              <p><Trans>Vi har kunn­­&shy;skap om container-teknologi, sky­­&shy;platt&shy;former, auto­­&shy;matise­­&shy;ring og CI/CD (Continuous Inte­­&shy;gration / Continous Develop­­&shy;ment).</Trans></p>
              </div>
              <div className={styles.buzzwords}>
                <p><Trans>Docker, Kubernetes, Jenkins, Travis CI, CircleCI, Bamboo, Puppet, Chef og Octopus Deploy. Microsoft Azure, Google Cloud og AWS.</Trans></p>
              </div>
              </div>
            </Animate>
            <Animate type="slide-lr-short" delay={400} animateOnce>
            {/* <i className="fas fa-comment"></i> */}
            <i className={`${styles.fas} ${styles['fa-comments']}`}></i>
              <h2><Trans>Prosjektledelse</Trans></h2>
              <div className={styles.box}>

              <div className={styles.info}>
                <p><Trans>Konsulen­­&shy;tene i Good­­&shy;byte admini­­&shy;st­­&shy;re­­&shy;rer både menn­­&shy;esker og tek­­&shy;niske ressu­­&shy;rser for å opp­­&shy;nå prosjek­­&shy;tets mål i overens­­&shy;stemme­­&shy;lse med avta­­&shy;le om kvali­­&shy;tet, tid og kost­­&shy;nader. Vi sørg­­&shy;er for at de invol­­&shy;ve­­&shy;rte i pro­­&shy;sjek­­&shy;tet arbeid­­&shy;er effekt­­&shy;ivt med sine spesi­­&shy;fik­­&shy;ke opp­­&shy;gav­­&shy;er.</Trans></p>
              </div>
              <div className={styles.buzzwords}>
                <p><Trans>Agile development, Scrum, Kanban, LEAN.</Trans></p>
              </div>
              </div>
            </Animate>
          </Columns>
          <Button href="#" onClick={() => setShowKeywords(!showKeywords)} className="competency">{showKeywords ? <Trans>Informasjon</Trans> : <Trans>Nøkkelord`</Trans>}</Button>
      </Sections>
    </div>
  );
}

export default Home;
