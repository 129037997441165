import React, { FunctionComponent } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styles from './Animate.module.scss';

type AnimateProps = {
  animateOnce?: boolean;
  className?: string;
  type: string;
  delay?: number;
  offset?: number;
}

export const Animate: FunctionComponent<AnimateProps> = ({ animateOnce = false, className = '', delay = 0, offset = 0, children, type }) => <ScrollAnimation animateOnce={animateOnce} animateIn={styles.animated} className={`${styles[type]} ${className}`} delay={delay} offset={offset}>{children}</ScrollAnimation>