import React, { FunctionComponent, useState } from 'react'
import { Trans, t } from "@lingui/macro";
import { I18n } from "@lingui/react"
import { Axios, db } from '../../firebase/firebaseConfig'
// import './styled.scss'
import { Button } from '../Button/Button';
import styles from "./ContactForm.module.scss";

type ContactFormProps = {
  dark: boolean;
}

export const ContactForm: FunctionComponent<ContactFormProps> = ({ dark }) => { 
// const ContactForm = () => {
  const [formData, setFormData] = useState({name: '', email: '', message: ''})
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const updateInput = (e: { target: { name: any; value: any } }) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setSending(true);
    sendEmail();
  }
  const sendEmail = () => {
    Axios.post(
      'https://us-central1-goodbyte-4d76f.cloudfunctions.net/submit',
      formData
    )
      .then(res => {
        db.collection('emails').add({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          time: new Date(),
        })
        setFormData({
          name: '',
          email: '',
          message: '',
        })
        setSent(true);
        setSending(false);
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    sent ?
      <div className={`${styles.thanks} ${dark ? styles.dark : styles.light}`}><Trans>Takk for henvendelsen, vi vil ta kontakt med deg så fort som mulig!</Trans></div>
    :
    <>
      <form onSubmit={handleSubmit} className={`${styles.form} ${dark ? styles.dark : styles.light} ${sending ? styles.sending : ''}`}>
        <I18n>
          {({ i18n }) => (
            <>
              <input
                type="text"
                name="name"
                placeholder={i18n._(t`Navn`)}
                onChange={updateInput}
                value={formData.name || ''}
                className={styles.input}
              />
              <input
                type="email"
                name="email"
                placeholder={i18n._(t`Epost`)}
                onChange={updateInput}
                value={formData.email || ''}
                className={styles.input}
              />
              <textarea
                // type="text"
                name="message"
                placeholder={i18n._(t`Melding`)}
                onChange={updateInput}
                value={formData.message || ''}
                className={styles.textarea}
              />
            </>
          )}
        </I18n>
        <Button button className="contact-form">
          <span className={styles['button-text']}>Send</span>
          <span className={styles['sk-folding-cube']}>
            <span className={`${styles['sk-cube1']} ${styles['sk-cube']}`}></span>
            <span className={`${styles['sk-cube2']} ${styles['sk-cube']}`}></span>
            <span className={`${styles['sk-cube4']} ${styles['sk-cube']}`}></span>
            <span className={`${styles['sk-cube3']} ${styles['sk-cube']}`}></span>
          </span>
        </Button>
      </form>
    </>
  )
}

// export default ContactForm