import React, {useContext, Fragment} from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Trans } from "@lingui/macro";
import { Sections } from 'components/Sections/Sections';
import { ContactForm } from 'components/ContactForm/ContactForm';
import ThemeContext from 'context/ThemeContext';
import styles from './Contact.module.scss';

const Contact: React.FC = () => {
  const { dark } = useContext(ThemeContext);

  return (
    <div className={`${dark ? styles.dark : styles.light}`}>
      <Sections className={styles.contact} id="contact" type="intro" bigText={<Trans>Kontakt oss</Trans>} smallText={<Fragment><Trans>La oss starte noe unikt <strong>sammen</strong>!<br />Hvordan kan vi hjelpe?</Trans></Fragment>} />

      <div className={styles['contact-form']}>
        <ScrollAnimation animateIn={styles.background} animateOnce />
        {/* <div className={styles['form-container']}> */}
        <ScrollAnimation animateIn={styles.animated} className={styles['form-container']} animateOnce>
          {/* <h2>Vi blir glade om vi hører fra deg</h2> */}
          <h2><Trans>Vi hører gjerne fra deg</Trans></h2>
          <p><Trans>Fortell oss behovet ditt ved å fylle ut følgende skjema, så kontakter vi deg.</Trans></p>
          <div className={styles.form}>
            <ScrollAnimation animateIn={styles['form-line']} animateOnce delay={1500} />
            <ContactForm dark={dark} />
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default Contact;
