import React, { FunctionComponent, useContext, ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import styles from './Sections.module.scss';
import ThemeContext from '../../context/ThemeContext';

type SectionsProps = {
  height?: string;
  width?: string;
  type?: string;
  className?: string;
  id?: string;
  bigText?: ReactNode | string;
  smallText?: ReactNode;
}

export const Sections: FunctionComponent<SectionsProps> = ({height = 'auto', width = 'auto', type = '', className = '', id = '', bigText = null, smallText = null,children }) => { 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (<section className={`${styles[type]} ${useContext(ThemeContext).dark ? styles.dark : styles.light} ${className}`} id={id}>
    {type === 'intro' &&
      <Link to="/" className={styles.logo}><img src="/img/logo.svg" alt="Goodbyte" /></Link>
    }
    {(smallText || bigText) &&
      <div className={styles.text}>
        {bigText && <h1>{bigText}</h1>}
        {smallText && <h2><ScrollAnimation animateIn={styles.animate}>{smallText}</ScrollAnimation></h2>}
      </div>
    }
    { children }
  </section>);
}