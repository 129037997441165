import React, { useContext, FunctionComponent } from 'react';

import ThemeContext from '../../context/ThemeContext';
import styles from './LanguageSelector.module.scss';

type LanguageSelectorProps = {
  language: string;
  onChangeLanguage: any;
}

export const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({language, onChangeLanguage}) => {
  function handleChange(value: string) {
    onChangeLanguage(value);
  }
  
  return (
    <div className={`${styles['language-selector']} ${useContext(ThemeContext).dark ? styles.dark : styles.light}`}>
      <button className={`${language === 'no' ? styles.selected : ''} ${styles.button} ${styles.no}`} onClick={() => handleChange('no')} />
      <span>|</span>
      <button className={`${language === 'en' ? styles.selected : ''} ${styles.button} ${styles.en}`} onClick={() => handleChange('en')} />
      {/* <select onChange={handleChange} value={language}>
        <option value="en">English</option>
        <option value="no">Norsk</option>
      </select> */}
    </div>
  );
}