import React, { Fragment, FunctionComponent, useContext, useState, useEffect } from 'react';
import { Trans } from "@lingui/macro";
// import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';
// import ThemeContext from 'context/ThemeContext';
import styles from './Menu.module.scss';

type MenuProps = {

}

export const Menu: FunctionComponent<MenuProps> = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [prevScrollpos, setScrollPos] = useState(window.pageYOffset);
  const [visibleScroll, setMenuVisibleScroll] = useState(true);
  const [menuHover, setMenuHover] = useState(0);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    setScrollPos(currentScrollPos);
    setMenuVisibleScroll(visible);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    if (menuOpen) {
      document.body.classList.add('menu-visible');
    } else {
      document.body.classList.remove('menu-visible');
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });



  const iframe = document.querySelectorAll('iframe');
  if (iframe !== undefined && iframe[0] !== undefined) {
    if (!window.matchMedia("(min-width: 1200px)").matches) {
      iframe.forEach(i => {
        // i.src = '';
        // @ts-ignore
        i.parentNode.removeChild(i)
      });
    }
  }

  const dark = useContext(ThemeContext).dark;

  return (
    <Fragment>
      <div id={styles['header-menu']} className={`${menuOpen ? styles.open : ''} ${visibleScroll ? '' : styles['nav-up']} ${dark ? styles.dark : styles.light}`} onClick={() => setMenuOpen(!menuOpen)}>
          <div className={styles['circle-bg']}>
          </div>
          <div id={styles['nav-icon']}>
              <span></span>
              <span></span>
              <span></span>
          </div>
      </div>
      {/* <!--Logo + Burger Menu-->
      <!--Menu--> */}
      <nav id={styles['menu-container']} className={`${styles['menu-container']} ${menuOpen ? styles.active : ''} ${dark ? styles.dark : styles.light}`}>
        <Link to="/" onClick={() => setMenuOpen(false)} className={styles.logo}><img src={dark ? '/img/logo-gray.svg' : '/img/logo.svg'} alt="Goodbyte logo" /></Link>
        <div className="bg-image select-1"></div>
        <div className="bg-image select-2"></div>
        <div className="bg-image select-3"></div>
        <div className="bg-image select-4"></div>
        <div className={styles['full-width-menu-container']}>
            
          <div className={styles.column}>
            <ul className={styles['main-nav-ul']}>
              <li onMouseEnter={() => setMenuHover(1)} onMouseLeave={() => setMenuHover(0)}><Link to="/om-oss" onClick={() => setMenuOpen(false)}><Trans>Om oss</Trans></Link></li>
              <li onMouseEnter={() => setMenuHover(2)} onMouseLeave={() => setMenuHover(0)}><Link to="/kontakt" onClick={() => setMenuOpen(false)}><Trans>Kontakt oss</Trans></Link></li>
              <li onMouseEnter={() => setMenuHover(3)} onMouseLeave={() => setMenuHover(0)}><Link to="/" onClick={() => setMenuOpen(false)}><Trans>Forsiden</Trans></Link></li>
                {/* <li><a data-link-name="select-1" href="https://www.antmoves.com/en/about">About us</a></li>
                <li><a data-link-name="select-4" href="https://www.antmoves.com/en/services">Our services</a></li>
                <li><a data-link-name="select-2" href="https://www.antmoves.com/en/case-studies">Our work</a></li>
                <li><a data-link-name="select-3" href="https://www.antmoves.com/en/contact-us">Contact us</a></li> */}
            </ul>
            <div className={styles.extra}>
              {/* <p><a href="https://goo.gl/maps/LotXY1wc9p62">Ilioupoleos 86, 172 37, Dafni, Attica</a></p> */}
              <p>
                <a href="mailto:hey@goodbyte.no">hei@goodbyte.no</a><span className="d-lg-block d-none"></span>
                {/* <span className="d-inline d-lg-none"> | </span> */}
                {/* <a href="tel:+302102241210">+30 2102241210</a>
                <a href="https://apply.workable.com/antmoves/" className="d-none d-lg-block"><span className="font-weight-700">Join the team!</span></a> */}
              </p>
              {/* <ul className="list-inline line-height-60">
                <li className="list-inline-item mr-lg-4 mr-2 d-inline mt-1"><a href="https://www.facebook.com/AntMoves/" target="_blank">Facebook</a></li>
                <li className="list-inline-item mr-lg-4 mr-2 d-inline mt-1"><a href="https://www.instagram.com/antmoves/" target="_blank">Instagram</a></li>
                <li className="list-inline-item mr-lg-4 mr-2 d-inline mt-1"><a href="https://www.linkedin.com/company/2663781/" target="_blank">LinkedIn</a></li>
              </ul> */}
            </div>
          </div>
          <div className={styles.videos}>
            <div className={`${styles.reveal} ${menuHover ? styles.show : ''}`}></div>
            {/* <div className="video-menu fullscreen-video" data-preview-id="377339998" data-vimeo-initialized="true">
              <iframe src="https://player.vimeo.com/video/377339998?loop=1&amp;background=1&amp;app_id=122963" width="426" height="240" allow="autoplay; fullscreen"  title="01SEAFOODLOOP4K_H264" data-ready="true" style={{width: '1664.95px', height: '938px', border: 0}}></iframe>
            </div>
            <div className="video-menu fullscreen-video" data-preview-id="377340010" data-vimeo-initialized="true">
              <iframe src="https://player.vimeo.com/video/377340010?loop=1&amp;background=1&amp;app_id=122963" width="426" height="240" allow="autoplay; fullscreen"  title="01SEAFOODLOOP4K_H264" data-ready="true" style={{width: '1664.95px', height: '938px', border: 0}}></iframe>
            </div> */}
            <div className={`${styles.video} ${menuHover === 1 ? styles.active : ''}`} data-preview-id="21294655" data-vimeo-initialized="true">
              <iframe src="https://player.vimeo.com/video/21294655?loop=1&amp;background=1&amp;muted=1&amp;autoplay=1&amp;autopause=0" width="426" height="240" allow="autoplay; fullscreen"  title="01SEAFOODLOOP4K_H264" data-ready="true"></iframe>
            </div>
            <div className={`${styles.video} ${menuHover === 2 ? styles.active : ''}`} data-preview-id="41738743" data-vimeo-initialized="true">
              <iframe src="https://player.vimeo.com/video/41738743?loop=1&amp;background=1&amp;muted=1&amp;autoplay=1&amp;autopause=0" width="426" height="240" allow="autoplay; fullscreen"  title="01SEAFOODLOOP4K_H264" data-ready="true"></iframe>
            </div>
            <div className={`${styles.video} ${menuHover === 3 ? styles.active : ''} ${styles.left}`} data-preview-id="377340010" data-vimeo-initialized="true">
              <iframe src="https://player.vimeo.com/video/377340010?loop=1&amp;background=1&amp;muted=1&amp;autoplay=1&amp;autopause=0" width="426" height="240" allow="autoplay; fullscreen"  title="01SEAFOODLOOP4K_H264" data-ready="true"></iframe>
            </div>
            {/* <div className={`${styles.video} ${menuHover === 3 ? styles.active : ''}`} data-preview-id="21294655" data-vimeo-initialized="true">
              <iframe src="https://player.vimeo.com/video/21294655?loop=1&amp;background=1&amp;muted=1&amp;autoplay=1&amp;background=1" width="426" height="240" allow="autoplay; fullscreen"  title="01SEAFOODLOOP4K_H264" data-ready="true" style={{width: '1664.95px', height: '938px', border: 0}}></iframe>
            </div> */}
          </div>
        </div>
      </nav>
    </Fragment>
  );
}
