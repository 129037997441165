import React, { FunctionComponent, useContext } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Trans } from "@lingui/macro";
import { useRouteMatch } from 'react-router-dom';
import ThemeContext from '../../context/ThemeContext';
import { Button } from '../Button/Button';
import styles from './Contact.module.scss';

export const Contact: FunctionComponent = () => <section className={`${useContext(ThemeContext).dark ? styles.dark : styles.light} ${styles.contact}`}>
    <ScrollAnimation animateIn={styles.animated}>
      <div className={styles.card}>
        <ScrollAnimation animateIn={styles.animated} offset={-50}>
          <div className={styles.content}>
            {!useRouteMatch('/kontakt') &&
              <>
                <h2><Trans>Fortell oss ditt behov!</Trans></h2>
                <Button className="contact-card" href="/kontakt"><Trans>Start her</Trans></Button>
              </>
            }
            {useRouteMatch('/kontakt') &&
              <>
                <h2><Trans>Bli kjent med oss!</Trans></h2>
                <Button className="contact-card" href="/om-oss"><Trans>Start her</Trans></Button>
              </>
            }
          </div>
        </ScrollAnimation>
      </div>
    </ScrollAnimation>
  </section>
