import React, { FunctionComponent } from 'react';
import styles from './logo.module.scss';

type LogoProps = {
  width?: string;
  dark?: boolean;
  position?: string;
}

export const Logo: FunctionComponent<LogoProps> = ({dark = false, width = 'full', position = 'left' }) => <div className={`${styles.logo} ${dark ? styles.dark : styles.light} ${styles[position]}`} />
