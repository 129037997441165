import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeSwitch } from 'components/ThemeSwitch/ThemeSwitch';
import { LanguageSelector } from './components/LanguageSelector/LanguageSelector';
import { Contact as ContactComponent} from 'components/Contact/Contact';
import { Footer } from 'components/Footer/Footer';
import { Menu } from 'components/Menu/Menu';
import Home from 'pages/Home/Home';
import About from 'pages/About/About';
import Contact from 'pages/Contact/Contact';
import Personvern from 'pages/Personvern/Personvern';

type AppProps = {
  language: string;
  onLanguageChange: any;
}

const App: React.FC<AppProps> = ({ language, onLanguageChange }) => {
  return (
    <div className="app fade">
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/om-oss">
          <About />
        </Route>
        <Route path="/kontakt">
          <Contact />
        </Route>
        <Route path="/personvern">
          <Personvern />
        </Route>
      </Switch>
      <ContactComponent />
      <ThemeSwitch />
      <LanguageSelector
        language={language}
        onChangeLanguage={onLanguageChange}
      />
      <Footer />
      <Menu />
    </Router>
    </div>
  );
}

export default App;
