import React, { FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from "@lingui/macro";
import ThemeContext from '../../context/ThemeContext';
import styles from './Footer.module.scss';

export const Footer: FunctionComponent = () => <section className={`${useContext(ThemeContext).dark ? styles.dark : styles.light} ${styles.footer}`}>
    <Link to="/"><img src="/img/logo-gray.svg" alt="Goodbyte" className={styles.logo} /></Link>
    <p className={styles.copyright}>&copy; <Trans>Goodbyte 2020. Alle rettigheter. <Link to="/personvern">Vilkår for bruk, cookies &amp; personvern</Link></Trans>.</p>
  </section>
