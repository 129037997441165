import React, { FunctionComponent, useContext } from 'react';
import styles from './Columns.module.scss';
import ThemeContext from '../../context/ThemeContext';

type ColumnsProps = {
  className ?: String;
  noPadding?: boolean;
  noOverflowHidden?: boolean;
  type?: string;
}

export const Columns: FunctionComponent<ColumnsProps> = ({ children, className = '', noPadding = false, noOverflowHidden = false, type = '' }) => { 
  const numColumns = React.Children.count(children);
  return (<div style={{overflow: !noOverflowHidden ? 'hidden' : ''}}><section className={`${useContext(ThemeContext).dark ? styles.dark : styles.light} ${styles.columns} ${styles['columns-' + numColumns]} ${className} ${noPadding ? styles['no-padding'] : ''} ${type !== '' ? styles[type] : ''}`}>
    { children }
  </section></div>);
}